import React, {useRef, useState} from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import settings from "../../settings";
import AXIOS from "../../axios-config";
import cogoToast from "cogo-toast";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Spinner from "../components/spinner";
import ReCAPTCHA from "react-google-recaptcha";

//components
import ExperienceCard from "../components/experiencecard";
import MonthSlider from "../components/MonthSlider";
import CustomMonthSlider from "../components/CustomMonthSlider";
import VideoBackground from "../components/videoBackground";
import BmwExpCenterGlance from "../components/bmwGlance/bmwExpCenterGlance";

// images
const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/experiences/bmw-experiences.jpg`;
const topBannerVideo = `${settings.IMAGES_BASE_URL}/videos/experience-video.mp4`;
const performancedrive = `${settings.IMAGES_BASE_URL}/images/experiences/bmw-performance-drive.jpg`;
const performanceDriveWest = `${settings.IMAGES_BASE_URL}/images/experiences/performance-drive-west.jpg`;
const mtrackdrive = `${settings.IMAGES_BASE_URL}/images/experiences/m-track-drive.jpg`;
const excursions = `${settings.IMAGES_BASE_URL}/images/experiences/excursions.jpg`;
const bmwtrackmeet = `${settings.IMAGES_BASE_URL}/images/experiences/bmw-track-meet.jpg`;
const privateinstruction = `${settings.IMAGES_BASE_URL}/images/experiences/private-instruction.jpg`;
const newowner = `${settings.IMAGES_BASE_URL}/images/experiences/bmw-exp-notd.jpg`;
const ccaDay = `${settings.IMAGES_BASE_URL}/images/experiences/bmw-exp-cca-day.jpg`;
const nurburgring = `${settings.IMAGES_BASE_URL}/images/experiences/nurburg-experience.jpg`;
const mSummerBanner = `${settings.IMAGES_BASE_URL}/images/experiences/m-summer-competition.jpg`;
const oktoberfestBanner = `${settings.IMAGES_BASE_URL}/images/experiences/oktoberfest-exp.jpg`;
const mMixedRealityBanner = `${settings.IMAGES_BASE_URL}/images/experiences/m-mixed-reality-experience.jpg`;

const today = new Date();
const oktoberfest = today > new Date(2024,8,6) && today <= new Date(2024,9,27);

const ExperiencesPage = () => {
// class ExperiencesPage extends React.Component {
  const contentRef = useRef(null)
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [showForm,setShowForm] = useState(false);
  

  const validationSchema = Yup.object().shape({
    fullname: Yup.string()
      .required("Please enter your full name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    email: Yup.string()
      .email()
      .required("Please enter your email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Your email address is incorrect"
      ),
  });

  const errorHandling = (setSubmitting) => {
    captchaRef.current.reset();
    setCaptchaToken("");
    setIsLoading(false);
    setSubmitting(false)
    window.grecaptcha.reset();
  }


  const handleSubmit = async (values, { setSubmitting, resetForm}) => {
    setIsLoading(true) 
    setSubmitting(true);
    const headers = {
      'captchaToken': captchaToken
    };

    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return
    }
    AXIOS.post("/api/experiences", values, {headers})
      .then(() => {
        resetForm();
        errorHandling(setSubmitting);
        cogoToast.success("Your form has been submitted successfully", {
          position: "top-center",
        });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", {
          position: "top-center",
        })
      }).finally(() => {
        errorHandling(setSubmitting);
      });
  };

  const handleNurburgringSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true) 
    setSubmitting(true);

    const headers = {
      'captchaToken': captchaToken
    };

    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return
    }

    AXIOS.post("/api/nurburgring-form", values, {headers})
      .then(() => {
        resetForm();
        errorHandling(setSubmitting);
        cogoToast.success("Your form has been submitted successfully", {
          position: "top-center"
        });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", {
          position: "top-center"
        });
      }).finally(() => {
        errorHandling(setSubmitting);
      });
  };

  const scrollToContent = () => {
    if(!contentRef) return;

    contentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const getRowType =(rowNum) => {
    if (rowNum % 2 === 0) {
      return "row-reverse";
    } else {
      return "row";
    }
  };

  let rowNum = 0;

  const getFirstCard = () => { 
    if (oktoberfest) {
      return (
        <>
          <ExperienceCard
            rowType={getRowType(++rowNum)}
            image={oktoberfestBanner}
            imageAlt="OKTOBERFEST ON THE WEST"
            header="OKTOBERFEST ON THE WEST"
            text="Oktoberfest returns to the west coast on October 27, 2024 for some driving fun and delicious food. Bring your friends and have a thrilling time on track with the Rat Race, Drag Race, Timed Autocross, Lead/Follow and Hot Laps. Dinner is included. Spectators can enjoy our take on the traditional Oktoberfest dinner trackside – with big German pretzels, schnitzel and much more. Come celebrate German tradition with a side dish of speed."
          >
            <span className="small__note">
              *Two people per car. All participants must purchase a driving ticket; no ride-along options are available. You must be 18 or older with a valid driver’s license to participate in driving events. Children 12 and under are free. The event will be held rain or shine.
            </span>
            <br />
            <br />
            <p className="bold"> Driving Event Ticket with Oktoberfest Reception: 2PM - 7PM - $500</p>
            <a className="nav-btn" href="https://pds.eventsbmw.com/?brand=BMW&date=2024-10-27&class=Oktoberfest_Driving_Participant" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-blue">Book Now</button>
            </a><br/><br/>
            <p className="bold"> Spectator Ticket with Oktoberfest Reception: 5PM – 7PM - $75</p>
            <p className="sub-para">You’re welcome to watch many of our driving events, with complimentary coffee and snacks, beginning at 2PM.</p>
            <a className="nav-btn" href="https://pds.eventsbmw.com/?brand=BMW&date=2024-10-27&class=Oktoberfest_Spectator" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-blue">Book Now</button>
            </a>
          </ExperienceCard>
        </>
      )
    } 
  }
    return (
      <Layout>
        <SEO
          title="BMW DRIVING EXPERIENCES | BMW Performance Driving School"
          description="BMW Driving Experiences offer a quick fix of speed in a variety of settings. Take a BMW X vehicle off-road, or push a BMW M car to the limit on a closed course."
          keywords="bmw experiences, bmw m driving school, bmw m performance driving school experiences, bmw driving school experience, BMW driving EXPERIENCES"
        />
        <VideoBackground
            url={topBannerVideo}
            poster={topbannerDesktop}
            hideMobileHeader={true}
            topLeftChildren={
              <div className="text_left">
                <div>DON’T DRIVE A BMW.</div>
                <div>EXPERIENCE ONE.</div>
              </div>
            }
            onArrowClick={scrollToContent}
        />
        <section className="experience" ref={contentRef}>
          <div className="container">
            <div className="experience__header">
              <h2>BMW EXPERIENCE CENTER</h2>
              <p>
                Get your BMW fix, even if you only have a few free hours. BMW Driving Experiences offer samples of what many of our classes feature. It’s a full-on thrill, without the full day.
              </p>
            </div>
          </div>
          <div className="experience__content">
            {getFirstCard()}
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={mMixedRealityBanner}
              imageAlt="M MIXED REALITY"
              header="M MIXED REALITY"
              subheader="WELCOME TO THE VIRTUAL REAL WORLD"
            >
              <p>
                A groundbreaking innovation: the world’s first driving experience where the virtual and physical worlds merge and the vehicle itself becomes the controller. Included in this half-day driving event is the Rat Race, Performance Drive and the M Mixed Reality Experience. You’ll drive a BMW M car on an open track area, with a digital track laid out through a Virtual Reality headset. It’s unique event that blurs the boundaries between technology and reality.
                <br/>
                <br/>
                M Mixed Reality is led by professional driving instructors on a closed course, with customized vehicle technology specific to this experience.
              </p>
              <br/>
              <p className="bold">8:30AM - 12PM</p>
              <p className="bold">12 people per event</p>
              <p className="bold">$750 per person.</p><br/>
              <MonthSlider 
                monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
                backgroundColor="rgb(203 203 203)"
                brand="M"
                urlQuery="&class=M_Mixed_Reality_Experience&location=Thermal,%20CA"
              />
            </ExperienceCard>
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={mSummerBanner}
              imageAlt="M SUMMER COMPETITION"
              header="M SUMMER COMPETITION"
              subheader="BEAT THE HEAT"
            >
              <p>
                A thrilling summer competition experience with timed runs and drag racing. Come out on top, and you’ll get a prize (and bragging rights). Available at our Thermal location only. Classes available June - September 2024.
              </p>
              <br/>
              <p className="bold">24 people per event</p>
              <p className="bold">2 events available from 8AM - 10AM</p>
              <p className="bold">2 events available from 10AM - 12PM</p>
              <p className="bold">$199 per person.</p>
              <CustomMonthSlider
								monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
								backgroundColor="rgb(203 203 203)"
                brand="M"
                urlQuery="&class=M_Summer_Competition&location=Thermal,%20CA"
							/>
            </ExperienceCard>
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={performancedrive}
              imageAlt="BMW PERFORMANCE DRIVING EXPERIENCE - EAST"
              header="BMW PERFORMANCE DRIVING EXPERIENCE - EAST"
              text="Enjoy a sample of the many of the vehicles we have at the Performance Center. Take the wheel of a BMW X vehicle as you follow an instructor through our challenging off-road course. Then, head to the track and rotate between various BMW vehicles, driving each through tight corners and fast straightaways*. It’s a ride you won’t soon forget."
            >
              <span className="small__note">
                *Exercises are subject to change without prior notice.
              </span>
              <br />
              <br />
              <p className="bold">Price: $299 | Time: Up to 2 hours</p>
              <MonthSlider
								monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
								backgroundColor="rgb(203 203 203)"
                urlQuery="&location=Spartanburg%2C%20SC&brand=BMW&class=BMW_Performance_Center_Drive"
							/>
            </ExperienceCard>
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={performanceDriveWest}
              imageAlt="BMW PERFORMANCE DRIVING EXPERIENCE - WEST"
              header="BMW PERFORMANCE DRIVING EXPERIENCE - WEST"
              text="Sample our driving buffet – West Coast style. Rotate between various BMW vehicles, driving each through tight corners and fast straightaways on track. Then, don some VR goggles for our M Mixed Reality Experience. It’s a ride you won’t soon forget."
            >
              <p>M Mixed Reality is led by professional driving instructors on a closed course, with customized vehicle technology specific to this experience.</p>
              <span className="small__note">
                *Exercises are subject to change without prior notice.
              </span>
              <br />
              <br />
              <p className="bold">Price: $299 | Time: Up to 2 hours</p>
              <MonthSlider
								monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
								backgroundColor="rgb(203 203 203)"
                urlQuery="&location=Thermal%2C%20CA&brand=BMW&class=BMW_Performance_Center_Drive"
							/>
            </ExperienceCard>

            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={mtrackdrive}
              imageAlt="BMW M Track Drive"
              header="BMW M TRACK DRIVING EXPERIENCE"
              subheader="GET YOUR TRACK DRIVING ADRENALINE FIX"
              text="This is the real deal. Featuring our most powerful and responsive vehicles, you'll get behind the wheel for six exhilarating laps at one of three Thermal Club racing circuits in California. Follow an instructor's car for a warm-up lap as they quickly bring you up to speed. Then, try to keep their car in your sights as speeds and G forces increase, pushing your M Car to its considerable limits. Can you keep up with them while maintaining control?"
            >
              <br />
              <p className="bold">Price: $299</p>
              <p className="bold"> Time: Up to 1 hour</p>
              <MonthSlider
                monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
                backgroundColor="rgb(203 203 203)"
                urlQuery="&class=M_Track_Drive"
              />
            </ExperienceCard>
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              id="owners_track_drive"
              image={newowner}
              imageAlt="BMW New Owner's Track Drive"
              header="New Owner’s Track Drive"
              subheader="WELCOME TO YOUR BMW TRACK DAY"
              text="Congratulations! You’ve just bought a brand new BMW. No doubt you understand what makes each model best-in-class, but what about best-on-track?"
            >
              <p>
                Each new BMW that’s purchased in 2024 comes with the opportunity
                to book two seats behind the wheel of a variety of BMWs, where
                you’ll experience driving at the limit. With the guidance of our
                professional instructors and the safety of our closed course,
                you’ll feel ready to tackle any challenge.
              </p>
              <p className="bold">
                Complimentary for you and one guest. Additional guests seats are
                $299 each.
              </p>
              <p>
                Please have your VIN, date of sale and location of your BMW
                Center ready when you call.
              </p>
              <a
                href="tel: 888-345-4269"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-black">
                  Call 888-345-4269 for more information
                </button>
              </a>
            </ExperienceCard>

            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={excursions}
              imageAlt="BMW Excursions"
              header="BMW DRIVING EXCURSIONS"
              subheader="A TRUE BMW ULTIMATE DRIVING EXPERIENCE"
              text="The BMW Performance Center will often host trips to unique driving destinations around the world such as Sweden and the famous Nürburgring located in Germany. Each excursion is guided by one of our accomplished driving instructors – providing guidance on local roads and beyond. To find out more information, enter your name and email address in the fields below, and a representative will reach out to you."
            >
              <Formik
                initialValues={{ fullname: "", email: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="experience-form">
                      <div className="input__group">
                        <Field
                          type="text"
                          name="fullname"
                          placeholder="Full Name"
                          className={classNames("nurburgring__input", {
                            "has-error": touched.fullname && errors.fullname
                          })}
                        />
                        {errors.fullname && touched.fullname && (
                          <span className="input-feedback">
                            {errors.fullname}
                          </span>
                        )}
                      </div>
                      <div className="input__group">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          className={classNames("nurburgring__input", {
                            "has-error": touched.email && errors.email
                          })}
                        />
                        {errors.email && touched.email && (
                          <span className="input-feedback">{errors.email}</span>
                        )}
                      </div>
                      <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/>
                      <button
                        type="submit"
                        className="btn btn-blue"
                        disabled={isSubmitting}
                      >
                        {isLoading ? <Spinner /> : "Submit"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </ExperienceCard>
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={bmwtrackmeet}
              imageAlt="BMW Track Meet"
              header="BMW TRACK MEET"
              subheader="HIGH-SPEED NETWORKING"
              text="The BMW Track Meet is an enjoyable driving and dining event that elevates business networking, client entertaining and gift-giving to a whole new level. This half-day gathering includes a delicious chef-prepared lunch and three exhilarating driving sessions in a fleet of brand-new BMWs on our closed course."
            >
              <br/>
              <p className="bold">Price: $499</p>
              <p className="bold"> Time: 5 hours</p>
              <MonthSlider
                monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
                backgroundColor="rgb(203 203 203)"
                urlQuery="&class=BMW_Track_Meet"
              />
            </ExperienceCard>
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={privateinstruction}
              imageAlt="BMW Driving Instructor"
              header="PRIVATE BMW INSTRUCTION"
              subheader="THE ONLY BMW PROFESSIONAL DRIVING EXPERIENCE"
              text="Every instructor at the BMW Performance Center has years of professional racing experience under their belts. Spend a day with them at the track for one-on-one instruction, and their sole focus becomes making you a better driver. Available upon request, private instruction can make for both a memorable experience and training to propel you to the next level of driving competition."
            >
              <p>
                Sessions scheduled any day between 8 AM and 4 PM may be scheduled 14 days in 
                advance<br/> Sessions scheduled between 4 PM and 8 PM may be scheduled up to 3 
                months in advance. 
              </p>
              <br/>
              <p className="bold">Price: $650 per hour with a 2 hour minimum. $500 for each additional hour.</p>
              <a
                href="tel: 888-345-4269"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-black">
                  Call 888-345-4269 for more information
                </button>
              </a>
            </ExperienceCard>

            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={ccaDay}
              imageAlt="BMW CCA Performance Center Day"
              header="BMW CCA DAY"
              subheader="THE BMW TRACK EXPERIENCE FOR THE ULTIMATE FAN CLUB"
              text="Being a member of the BMW Car Club of America has many perks, and they include 15 percent off at the BMW Performance Center’s CCA Member Day. Available at both the East and West coast locations, you’ll be able to get instruction in the latest BMW models and push them to their limits. It’s a day of BMW, and it’s only available for CCA members."
            >
              <div className="buttons">
                <a
                  href="tel: 888-345-4269"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-black">
                    Call 888-345-4269 for more information
                  </button>
                </a>
                <a
                  href="https://www.bmwcca.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-black">Become a CCA Member</button>
                </a>
              </div>
            </ExperienceCard>
            <ExperienceCard
              rowType={getRowType(++rowNum)}
              image={nurburgring}
              imageAlt="BMW Nurburgring Drive"
              header="BMW NÜRBURGRING DRIVE"
              subheader="DRIVE THE NÜRBURGRING FOR AN AUTHENTIC BMW RACING EXPERIENCE"
              text="This five-day excursion lets you experience a BMW on the Nürburgring, Germany’s most famous race track. You’ll tour the track on foot, take in its iconic history and experience multiple track days. It’s a thrilling experience you won’t want to miss. This event sells out very quickly, so please fill out the fields below to get on our waiting list, and we’ll reach out when spots become available."
            >
              <Formik
                initialValues={{ fullname: "", email: "" }}
                validationSchema={validationSchema}
                onSubmit={handleNurburgringSubmit}
              >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="experience-form">
                      <div className="input__group">
                        <Field
                          type="text"
                          name="fullname"
                          placeholder="Full Name"
                          className={classNames("nurburgring__input", {
                            "has-error": touched.fullname && errors.fullname
                          })}
                        />
                        <span className="input-feedback input-error">
                            {errors.fullname}
                          </span>
                      </div>
                      <div className="input__group">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          className={classNames("nurburgring__input", {
                            "has-error": touched.email && errors.email
                          })}
                        />
                        <span className="input-feedback input-error">{errors.email}</span>
                      </div>
                      <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/>
                      <div className="nurburgring-buttons">
                        <button
                          type="submit"
                          className="btn btn-blue"
                          disabled={isSubmitting}
                        >
                          {isLoading ? <Spinner /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </ExperienceCard>
            <BmwExpCenterGlance/>
          </div>
        </section>
      </Layout>
    );
}
export default ExperiencesPage;
